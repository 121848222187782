import React from "react";
import { useDispatch, useSelector } from "react-redux";

import logoTwo from "../assets/images/lcs-logo-2.svg";
import aboutImg from "../assets/images/luke.jpeg";
import topRightImg from "../assets/images/corner-img.png";
import {
  SET_CATEGORY_LENGTH,
  SET_CURRENT_STEP,
  SET_USER_DETAIL,
} from "../store/actions";
// import { Button, Modal } from "bootstrap";
import "@popperjs/core";
import { WelcomeOne } from "./WelcomeOne";
import { WelcomeTwo } from "./Welcometwo";

export const Welcome = () => {

  const categoryLength = useSelector((state) => state.categoryLength);

  return (
    <>
      {console.log("categoryLength", categoryLength)}
      {categoryLength <= 4 ? <WelcomeOne /> : <WelcomeTwo />}
    </>
  );
};
