import React from "react";

import topRightImg from "../assets/images/corner-img.png";
// import { useDispatch, useSelector } from "react-redux";
// import { SET_CURRENT_STEP } from "../store/actions";
// import { API_URL } from "../config/constant";

export const Download = () => {
  // const currentStep = useSelector((state) => state.currentStep);
  // const userDetail = useSelector((state) => state.userDetail);
  // const dispatch = useDispatch();

  return (
    <>
      <div className="download-main d-flex flex-column justify-content-center">
        <img src={topRightImg} alt="lc survey" className="topright-img" />
        <h1 className="page-title mt-3 mt-lg-5 text-center">
          Congratulations on successfully completing
        </h1>
        <h2 className="dl-sub-text text-center">
          The You Care Quality of Lifestyle Survey!
        </h2>
        <p className="mb-0 mt-3 mt-lg-5 text-center mid-text">
          We appreciate your time and effort.
        </p>
        <p className="mb-0 text-center mid-text">
          Here’s a gift from us to you…
        </p>

        {/* <div className="bottom-row d-flex align-items-center mt-4 pt-4">
          <button
            className="btn btn-primary btn-2 mx-auto"
            onClick={() =>
              dispatch({ type: SET_CURRENT_STEP, value: currentStep - 2 })
            }
          >
            Back
          </button>
        </div> */}
        <div className="dl-bottom-links text-center mt-3">
          <div>
            <a
              href="https://lukecoutinho.com/blog/lifestyle-en/a-new-way-of-living/?utm=qols"
              rel="noreferrer noopener"
              target="_blank"
            >
              <h4>
                A New Way Of Living - Circadian Rhythm{" "}
                <strong> - Download from here</strong>
              </h4>
            </a>
          </div>

          <a
            href="https://www.lukecoutinho.com/books?utm=qols"
            rel="noreferrer noopener"
            target="_blank"
          >
            <h4>
              The Magic Immunity Pill - Lifestyle{" "}
              <strong> - Download from here</strong>
            </h4>
          </a>
        </div>
      </div>
    </>
  );
};
