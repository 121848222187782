import React, { useState } from "react";
import "@popperjs/core";
import topRightImg from "../assets/images/corner-img.png";
import { useDispatch } from "react-redux";
import { SET_SELECTED_CATEGORY_INDEX } from "../store/actions";

export const Skip = (props) => {
  const { selectedCategoryIndex, onContinue, onSkip } = props;
  return (
    <>
      <div className="skip-main">
        <div className="skip-child-1">
          <div className=" skip-child-2">
            <img src={topRightImg} alt="lc survey" className="topright-img" />
            <div className="skip-child-3">
              <h4>
                Our next lifestyle pillar is sexual wellness. The questions here
                might not apply to individuals under 18 years and those who are
                not sexually active.
              </h4>
              <h6>Check YES to continue or NO to skip this section. </h6>
              <div className="row justify-content-center">
                <div className="col-md-4 col-lg-5">
                  <button
                    className="btn btn-primary btn-2"
                    onClick={() => onContinue()}
                  >
                    Yes
                  </button>
                </div>
                <div className="col-md-4 col-lg-5">
                  <button
                    className="btn btn-primary btn-2 "
                    onClick={() => onSkip()}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
