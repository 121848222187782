import { createStore } from "redux";
import {
  SET_ANSWERS,
  SET_CATEGORY,
  SET_SIDEBAR_CATEGORY,
  SET_SELECTED_CATEGORY,
  SET_CURRENT_STEP,
  SET_QUESTIONS,
  SET_OVERALL_CATEGORY,
  SET_SELECTED_CATEGORY_INDEX,
  SET_USER_FORM_DATA,
  SET_USER_INVITE_FORM_DATA,
  SET_USER_DETAIL,
  SET_CATEGORY_LENGTH,
} from "./actions";

const defaultValues = {
  name: "",
  email: "",
  age: "",
  gender: null,
  city: "",
  country: "",
  dietPreference: "",
  preferenceDetails: "",
  ethnicity: "",
  heightUnit: "",
  height: "",
  weightUnit: "",
  weight: "",
  corporate_id: "",
};

const initialState = {
  categoryList: [],
  sideBarCategoryList: [],
  questionsList: [],
  answeredQuestionsList: [],
  currentStep: 1,
  userFormData: {},
  userInviteForm: [],
  selectedCategory: null,
  selectedCategoryIndex: null,
  overAllCategory: null,
  userDetail: defaultValues,
  categoryLength: 4,
};

const reducer = (state = initialState, action) => {
  // console.log("state", state)
  // console.log("action", action)
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.value,
      };
    case SET_CATEGORY:
      return {
        ...state,
        categoryList: action.value,
      };
    case SET_SIDEBAR_CATEGORY:
      return {
        ...state,
        sideBarCategoryList: action.value,
      };
    case SET_QUESTIONS:
      return {
        ...state,
        questionsList: action.value,
      };
    case SET_ANSWERS:
      return {
        ...state,
        answeredQuestionsList: [
          ...state.answeredQuestionsList,
          ...action.value,
        ],
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.value,
      };
    case SET_SELECTED_CATEGORY_INDEX: {
      return {
        ...state,
        selectedCategory:
          action.value === null ? null : state.categoryList[action.value],
        selectedCategoryIndex: action.value,
      };
    }
    case SET_USER_FORM_DATA:
      return {
        ...state,
        userFormData: action.value,
      };
    case SET_USER_INVITE_FORM_DATA: {
      return {
        ...state,
        userInviteForm: action.value,
      };
    }
    case SET_OVERALL_CATEGORY: {
      return {
        ...state,
        overAllCategory: action.value,
      };
    }
    case SET_USER_DETAIL: {
      return {
        ...state,
        userDetail: action.value,
      };
    }
    case SET_CATEGORY_LENGTH: {
      return {
        ...state,
        categoryLength: action.value,
      };
    }
    default:
      return state;
  }
};

const store = createStore(reducer);
export default store;
