export const SET_CATEGORY = "SET_CATEGORY";
export const SET_SIDEBAR_CATEGORY = "SET_SIDEBAR_CATEGORY";
export const SET_OVERALL_CATEGORY = "SET_OVERALL_CATEGORY";
export const SET_QUESTIONS = "SET_QUESTIONS";
export const SET_CURRENT_STEP = "SET_CURRENT_STEP";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
export const SET_ANSWERS = "SET_ANSWERS";
export const SET_SELECTED_CATEGORY_INDEX = "SET_SELECTED_CATEGORY_INDEX";
export const SET_USER_FORM_DATA = "SET_USER_FORM_DATA";
export const SET_USER_INVITE_FORM_DATA = "SET_USER_INVITE_FORM_DATA";
export const SET_USER_DETAIL = "SET_USER_DETAIL";
export const SET_CATEGORY_LENGTH = "SET_CATEGORY_LENGTH";
