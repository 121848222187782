import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import flIcon1 from "../assets/images/icon-play-btn.svg";
import flIcon2 from "../assets/images/icon-spec.svg";
import { API_URL } from "../config/constant";
import { SET_CURRENT_STEP, SET_SELECTED_CATEGORY } from "../store/actions";
import Loader from "./Loader";

export const ScoreDetails = () => {
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(true);
  const currentStep = useSelector((state) => state.currentStep);
  const selectedCategory = useSelector((state) => state.selectedCategory);
  const answeredQuestionsList = useSelector(
    (state) => state.answeredQuestionsList
  );
  const [score, setScore] = useState(null);
  const [result, setResult] = useState({});

  useEffect(() => {
    const questions = answeredQuestionsList.filter(
      (obj) => obj.category === selectedCategory.id
    );
    const totalScore = questions.reduce(
      (acc, obj) => acc + Number(obj.answer),
      0
    );
    const maxScore = questions.reduce(
      (acc, obj) => acc + Number(obj.maxScore),
      0
    );
    const minScore = questions.reduce(
      (acc, obj) => acc + Number(obj.minScore),
      0
    );
    setScore(
      Math.floor(((totalScore - minScore) / (maxScore - minScore)) * 100)
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log(score);
    if (score !== null) {
      getSurvey();
    }
    // eslint-disable-next-line
  }, [score]);

  const getSurvey = async () => {
    try {
      const { data } = await axios.post(`${API_URL}/score`, {
        categoryId: selectedCategory?.id,
        score: score,
      });
      console.log("data in Score", data);
      setResult(data.result);
    } catch (e) {
      console.log(e);
      // dispatch({ type: SET_SELECTED_CATEGORY, value: null });
      // dispatch({ type: SET_CURRENT_STEP, value: currentStep - 1 });
    } finally {
      setShowLoader(false);
    }
  };

  if (showLoader) return <Loader />;

  return (
    <>
      {console.log("result in ScoreDetails", result)}
      <div className="score-details-main">
        <div className="d-flex justify-content-between">
          <div>
            <h1 className="sub-title m-0">
              <span>Your Individual Score</span>
            </h1>
            <h2 className="sub-title m-0">
              <span>{result.meaning}</span>
            </h2>
          </div>
          <div className="congr-top-image">
            <img src={selectedCategory?.image} alt="lc survey" />
          </div>
        </div>

        <div className="dougnut-charts d-flex mt-4">
          <div className="chart-block text-center">
            <div className="chart-item">
              <span className="num">{score}%</span>
              <svg width="40" height="40" viewBox="0 0 40 40">
                <g
                  id=""
                  className="g1"
                  style={{ stroke: selectedCategory?.color }}
                >
                  <circle cx="20" cy="20" r="15.9" />
                </g>
                <g
                  id=""
                  className="g2"
                  style={{ strokeDashoffset: `-${score}` }}
                >
                  <circle cx="20" cy="20" r="15.9" />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <p
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: result?.description }}
        ></p>
        <div className="btn-row d-flex flex-column flex-md-row justify-content-between align-items-center mt-4">
          <button
            className="btn btn-primary btn-2 mb-4 mb-md-0"
            onClick={() => {
              dispatch({ type: SET_SELECTED_CATEGORY, value: null });
              dispatch({ type: SET_CURRENT_STEP, value: currentStep - 1 });
            }}
          >
            Back to Overall Score
          </button>
          <div className="featured-links flex-column flex-md-row d-flex justify-content-between">
            <div className="featured-link-item mb-3 mb-md-0">
              <a
                href={result?.readUrl}
                target="_blank"
                rel="noreferrer"
                className=" d-flex justify-content-between align-items-center"
              >
                <div className="me-3">
                  <div className="m-0 fl-title">{result?.readTitle}</div>
                  <p className="m-0">Read</p>
                </div>
                <figure>
                  <img src={flIcon2} alt="lc survey" />
                </figure>
              </a>
            </div>
            <div className="featured-link-item">
              <a
                href={result?.watchUrl}
                target="_blank"
                rel="noreferrer"
                className=" d-flex justify-content-between align-items-center"
              >
                <div className="me-3">
                  <div className="m-0 fl-title">{result?.watchTitle}</div>
                  <p className="m-0">Watch</p>
                </div>
                <figure>
                  <img src={flIcon1} alt="lc survey" />
                </figure>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
